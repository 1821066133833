import logo from './logo.svg';
import './App.css';
import {useCallback, useEffect, useState} from "react";
import { useSearchParams} from "react-router-dom";
import axios from "axios";
const tg = window.Telegram.WebApp;


function App() {
  const [searchParams, setSearchParams] = useSearchParams();

  const eventHandler = useCallback(  async (e)=>{
    const qr = e.data;


    //const url = 'https://2y4y5p6k6b.apigw.corezoid.com/webApp?qr=' + qr+'&id='+tg.initDataUnsafe.user.id;
    // Додаємо всі поточні searchParams до нового URL
    const params = new URLSearchParams(searchParams);
    params.set("qr", qr);
    params.set("id", tg.initDataUnsafe.user.id);

    const url = `https://p3iqg8csg3.apigw.corezoid.com/qrscantg?${params.toString()}`;
    //fetch(url);

    axios
        .get(url)
        .then((response) => {
          console.log("Response:", response.data);
        })
        .catch((error) => {
          console.error("Axios error:", error.response ? error.response.data : error.message);
        });

    setTimeout(function () {
      tg.closeScanQrPopup();
    }, 1000);
    setTimeout(function () {
      tg.close();
    }, 1000);
  },[])

  useEffect(()=>{
    tg.onEvent("qrTextReceived", eventHandler)
    return () =>{
      tg.offEvent("qrTextReceived", eventHandler);
    }
  },[])

  useEffect( ()=>{
    tg.showScanQrPopup({text:"devall"});

  },[])

  return (
      <div className="App">
        @devall
      </div>
  );
}

export default App;
